<template>
  <div class="ssi-root">
    <div class="sr-title dp-flex flex-center">
      服务大厅
    </div>
    <div class="sr-one">
      <router-link :to="{path: 'serviceList',query: {sort:1}}"  target="_blank">
      <div class="sro-item">
        <img class="sro-item-img"
             src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_science_serviceA.png"/>
        <div class="sro-item-title">知识产权服务</div>
        <div class="sro-item-blank"/>
        <div class="sro-item-des ellipse-3">商标代理；专利代理；其他知识产权代理。科技项目和知识产权项目申报服务。知识产权培训，知识产权战略规划与专利预警咨询，知识产权标准化体系咨询
        </div>
        <div class="sro-item-button display-flex-center">查看更多</div>
      </div>
      </router-link>
      <router-link :to="{path: 'serviceList',query: {sort:2}}"  target="_blank">
      <div class="sro-item">
        <img class="sro-item-img"
             src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_science_serviceB.png"/>
        <div class="sro-item-title">技术转移</div>
        <div class="sro-item-blank"/>
        <div class="sro-item-des ellipse-3">
          技术许可证、技术对接平台、产学研结合、设备和软件购置、信息传播、技术帮助、创办新企业，企业孵化器等
        </div>
        <div class="sro-item-button display-flex-center">查看更多</div>
      </div>
      </router-link>
      <router-link :to="{path: 'serviceList',query: {sort:3}}"  target="_blank">
      <div class="sro-item">
        <img class="sro-item-img"
             src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_science_serviceC.png"/>
        <div class="sro-item-title">科技成果</div>
        <div class="sro-item-blank"/>
        <div class="sro-item-des ellipse-3">新产品、新工艺、新材料以及科技报告等
        </div>
        <div class="sro-item-button display-flex-center">查看更多</div>
      </div>
    </router-link>
      <router-link :to="{path: 'serviceList',query: {sort:4}}"  target="_blank">
      <div class="sro-item">
        <img class="sro-item-img"
             src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_science_serviceD.png"/>
        <div class="sro-item-title">法律服务</div>
        <div class="sro-item-blank"/>
        <div class="sro-item-des ellipse-3">公司法律、常年顾问、商业与并购、投融资法律服务、证券与资本市场、银行与金融、争议解决（诉讼与仲裁）以及国际贸易、知识产权保护等领域的法律服务
        </div>
        <div class="sro-item-button display-flex-center">查看更多</div>
      </div>
      </router-link>
      <router-link :to="{path: 'serviceList'}"  target="_blank">
      <div class="sro-item">
        <img class="sro-item-img"
             src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_science_serviceE.png"/>
        <div class="sro-item-title">更多</div>
        <div class="sro-item-blank"/>
        <div class="sro-item-des ellipse-3">其他科技服务、财税服务、人力资源服务等
        </div>
        <div class="sro-item-button display-flex-center">查看更多</div>
      </div>
      </router-link>
    </div>

    <div class="sr-two">
      <div class="sr-two-body">
        <div class="sr-two-item">
          <img width="50" height="50"
               src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_science_serviceF.png"/>
          <div class="srti-text">注册用户<span style="font-size: 20px;color: #2A8BEF;font-weight: bold">343,23</span>个</div>
          <div class="srti-button">
            <div class="srti-button-click display-flex-center">提需求</div>
            <div class="srti-button-click display-flex-center" style="margin-left: 15px">写评价</div>
          </div>
        </div>
        <div class="srti-slider"/>
        <div class="sr-two-item">
          <img width="50" height="50"
               src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_science_serviceG.png"/>
          <div class="srti-text">拥有专利<span style="font-size: 20px;color: #2A8BEF;font-weight: bold">234,687</span>条
          </div>
          <div class="srti-button">
            <div class="srti-button-click display-flex-center">提需求</div>
            <div class="srti-button-click display-flex-center" style="margin-left: 15px">写评价</div>
          </div>
        </div>
        <div class="srti-slider"/>
        <div class="sr-two-item">
          <img width="50" height="50"
               src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_science_serviceH.png"/>
          <div class="srti-text">拥有成果<span style="font-size: 20px;color: #2A8BEF;font-weight: bold">234,687</span>条
          </div>
          <div class="srti-button">
            <div class="srti-button-click display-flex-center">提需求</div>
            <div class="srti-button-click display-flex-center" style="margin-left: 15px">写评价</div>
          </div>
        </div>
        <div class="srti-slider"/>
        <div class="sr-two-item">
          <img width="50" height="50"
               src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_science_serviceI.png"/>
          <div class="srti-text">拥有科研专家<span style="font-size: 20px;color: #2A8BEF;font-weight: bold">234</span>位</div>
          <div class="srti-button">
            <div class="srti-button-click display-flex-center">提需求</div>
            <div class="srti-button-click display-flex-center" style="margin-left: 15px">写评价</div>
          </div>
        </div>
        <div class="srti-slider"/>
        <div class="sr-two-item">
          <img width="50" height="50"
               src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_science_serviceJ.png"/>
          <div class="srti-text">入驻服务机构<span style="font-size: 20px;color: #2A8BEF;font-weight: bold">235</span>家</div>
          <div class="srti-button">
            <div class="srti-button-click display-flex-center">提需求</div>
            <div class="srti-button-click display-flex-center" style="margin-left: 15px">写评价</div>
          </div>
        </div>
        <div class="srti-slider"/>
        <div class="sr-two-item">
          <img width="50" height="50"
               src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_science_serviceK.png"/>
          <div class="srti-text">入驻技术转移中心<span style="font-size: 20px;color: #2A8BEF;font-weight: bold">235</span>家
          </div>
          <div class="srti-button">
            <div class="srti-button-click display-flex-center">提需求</div>
            <div class="srti-button-click display-flex-center" style="margin-left: 15px">写评价</div>
          </div>
        </div>
      </div>
    </div>
    <div class="sr-title dp-flex flex-center">
      需求大厅
    </div>
    <div class="sr-require">
      <div
          @click="changeSelectRequireType(index)"
          v-for="(item,value,index) in requireList" :key="index"
          :class="['sr-require-item','display-flex-center',selectRequireIndex === index ? 'sr-require-item-select' : '',]">
        {{ value }}
      </div>
      <div class="sr-require-more" @click="goDemandHall">查看更多 》</div>
    </div>
    <div class="require-list">
      <div class="require-list-item" v-for="(item,index) in selectRequireItem" :key="item.id">
        <router-link
            :to="{ path: '/home/demandDetail', query: { id: item.id } }"
            target="_blank"
            style="color: #333333"
        >
          <div class="rli-title">
            <div class="rli-title-number display-flex-center">{{ index + 1 }}</div>
            <div class="rli-title-text ellipse-1">{{ item.title }}</div>
          </div>
          <div class="rli-content ellipse-2">
            {{ item.content }}
          </div>
          <div class="rli-bottom">
            <div class="rli-bottom-textA">预算金额：<span style="color: rgba(255, 114, 0, 1)">协商确定</span></div>
            <div class="rli-bottom-textB"> {{ item.updatedTime.split(" ")[0] }}</div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="sr-title dp-flex flex-center">
      热门店铺
    </div>
    <div class="shop-classify">
      <div
          @click="changeSelectShopType(0)"
          :class="['shop-classify-item','display-flex-center',selectShopIndex === 0 ? 'shop-classify-item-select' : '',]">
        技术转移中心
      </div>
      <div
          @click="changeSelectShopType(1)"
          :class="['shop-classify-item','display-flex-center',selectShopIndex === 1 ? 'shop-classify-item-select' : '',]"
          style="margin-left: 19px">服务机构
      </div>
      <router-link :to="{path:'scienceOutStoreList',query:{type:selectShopIndex}}">
      <div class="sr-require-more">查看更多 》</div>
      </router-link>
    </div>
    <div class="shop-list">
      <div class="shop-list-item" v-for="(item,index) in selectShopItem" :key="index" @click="goShopDetail(item.id)">
        <img :src="item.pic" width="98" height="98" style="object-fit: cover"/>
        <div class="shop-list-item-text">{{ item.name }}</div>
        <div class="shop-list-item-blank"/>
        <div class="shop-list-hover">
          <div class="slh-title">
            <img :src="item.pic" width="52" height="52"/>
            <span class="slh-title-text ellipse-1">{{item.name}}</span>
          </div>
          <div class="slh-des">暂无简介~</div>
          <div class="slh-button display-flex-center">进入店铺</div>
        </div>
      </div>
    </div>
    <div class="sr-title dp-flex flex-center" style="width: 1200px;position: relative">
      热门服务
      <router-link :to="{path: 'serviceList'}"  target="_blank">
      <div class="sr-require-more">查看更多 》</div>
      </router-link>
    </div>
    <div class="hot-service">

      <div class="hs-body" v-for="(item,index) in hotServiceList">
        <div class="hs-top display-flex-center">
          <img :src="item.pic" width="80" height="80"/>
        </div>
        <router-link :to="{path:'serviceDetail',query:{goodId:item.id}}">
        <div class="hs-bottom">
          {{item.name}}
          <Icon type="ios-arrow-dropright" class="hs-bottom-icon"/>
        </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {getScienceServiceIndex, getServiceNum} from "@/plugins/api/scienceServiceApi";
import {getIndexRequirementData} from "../../../plugins/api/indexApi";

export default {
  name: "ScienceServiceIndex",
  data() {
    return {
      requireList: [],//需求列表
      selectRequireItem: [],//选择的需求对象
      selectRequireIndex: 0,//选择的需求分类
      shopList: [],//店铺列表
      selectShopItem: [],//选择的店铺列表
      selectShopIndex: 0, //选择的热门店铺类型
      hotServiceList:[],//热门服务列表
    }
  },

  mounted() {
    this.initPage();
    this.initRequire();
  },

  methods: {
    //获取页面列表
    async initPage() {
      const json = await getScienceServiceIndex()
      if (json && json.code === 0) {
        const data = json.result.floors;
        this.shopList = data.slice(-2)
        const item = this.shopList[0].config.labels[0].objs;
        this.selectShopItem = item.length > 6 ? item.slice(-6) : item
        this.hotServiceList=data[4].config.labels[0].objs
      }
    },
    //获取需求列表
    async initRequire() {
      const json = await getIndexRequirementData();
      if (json && json.code === 0) {
        this.requireList = json.result
      }
      this.selectRequireItem = this.requireList[Object.keys(this.requireList)[0]].length > 4 ? this.requireList[Object.keys(this.requireList)[0]].slice(0, 4) : this.requireList[Object.keys(this.requireList)[0]]
    },
    //选择需求类型
    changeSelectRequireType(index) {
      this.selectRequireIndex = index
      this.selectRequireItem = this.requireList[Object.keys(this.requireList)[index]].length > 4 ? this.requireList[Object.keys(this.requireList)[index]].slice(0, 4) : this.requireList[Object.keys(this.requireList)[index]]
    },
    //选择店铺类型
    changeSelectShopType(index) {
      this.selectShopIndex = index;
      const item = this.shopList[index].config.labels[0].objs
      this.selectShopItem = item.length > 6 ? item.slice(-6) : item
    },
    //跳转需求大厅
    goDemandHall() {
      this.$router.push({
        path: "/home/DemandHall",
      });
    },
    //跳转店铺列表
    goShopDetail(id){
      this.$router.push({
        path:"/home/serviceOutStoreList",
        query:{shopId:id}
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.ssi-root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sr-title {
  font-size: 30px;
  font-weight: 400;
  color: #333333;
  margin-top: 50px;
  margin-bottom: 35px;
}

.sr-one {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1200px;
  margin-bottom: 55px;
}

.sro-item {
  display: flex;
  flex-direction: column;
  width: 224px;
  height: 327px;
  border-radius: 8px;
  cursor: pointer;
  border-top: 10px solid rgba(42, 139, 239, 1);
  box-shadow: 0 10px 10px 10px #e6eff7;
  align-items: center;
}

.sro-item:hover {
  box-shadow: 1px 1px 10px rgba(42, 139, 239, 1)
}

.sro-item:hover > .sro-item-button {
  visibility: visible;
}

.sro-item-img {
  width: 64px;
  height: 64px;
  margin-top: 30px;
}

.sro-item-title {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  margin-top: 12px;
  margin-bottom: 12px;
}

.sro-item-blank {
  width: 50px;
  height: 3px;
  background: #2F70E7;
  border-radius: 2px 2px 1px 1px
}

.sro-item-des {
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  color: #666666;
  margin-top: 20px;
  margin-left: 21px;
  margin-right: 21px;
  line-height: 28px;
}

.sro-item-button {
  width: 135px;
  height: 34px;
  background: linear-gradient(0deg, #0E61DF, #39A2F7);
  border-radius: 18px 18px 16px 16px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  visibility: hidden;
}

.sr-two {
  width: 100%;
  background-color: rgba(246, 250, 255, 1);
}

.sr-two-body {
  display: flex;
  flex-direction: row;
  height: 270px;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
}

.sr-two-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.srti-text {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-top: 20px;
}

.srti-slider {
  height: 180px;
  background: linear-gradient(0deg, #FFFFFF 0%, #349BF4 51%, #FFFFFF 100%);
  width: 1px;
}

.srti-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.srti-button-click {
  width: 56px;
  height: 24px;
  border: 1px solid rgba(24, 144, 255, 0.6);
  border-radius: 12px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(24, 144, 255, 0.6);
}

.sr-require {
  width: 1200px;
  padding-left: 162px;
  padding-right: 162px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.sr-require-more {
  position: absolute;
  right: 0;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  top: 10px;
  cursor: pointer;
}

.sr-require-item {
  width: 130px;
  height: 34px;
  background: #E3E9EE;
  border-radius: 17px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}

.sr-require-item-select {
  background: linear-gradient(0deg, #0E61DF, #39A2F7);
  color: #FFFFFF;
}

.require-list {
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.require-list-item {
  width: 580px;
  height: 148px;
  background: linear-gradient(180deg, rgba(42, 139, 239, 0.29), rgba(255, 255, 255, 0));
  border-radius: 6px;
  margin-top: 30px;
  padding: 25px 30px 15px 20px;
  box-shadow: 5px 5px 10px rgba(238, 244, 248, 1);
}

.rli-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rli-title-number {
  width: 22px;
  height: 22px;
  background: #39A2F7;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
}

.rli-title-text {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}

.rli-content {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 25px;
  margin-top: 10px;
}

.rli-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.rli-bottom-textA {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.rli-bottom-textB {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.shop-classify {
  display: flex;
  flex-direction: row;
  width: 1200px;
  justify-content: center;
  position: relative;
}

.shop-classify-item {
  width: 130px;
  height: 34px;
  background-color: #E3E9EE;
  border-radius: 17px;
  cursor: pointer;
}

.shop-classify-item-select {
  background: linear-gradient(0deg, #0E61DF, #39A2F7);
  color: #FFFFFF;
}

.shop-list {
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.shop-list-item {
  width: 360px;
  height: 208px;
  border-radius: 6px;
  box-shadow: 0 10px 10px 10px #e6eff7;
  margin-top: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 25px;
  align-items: center;
  cursor: pointer;
}

.shop-list-item:hover{
  box-shadow: 1px 1px 10px rgba(42, 139, 239, 1)
}

.shop-list-item-text {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
}

.shop-list-item-blank {
  width: 49px;
  height: 5px;
  background-color: #359CF5;
}

.shop-list-item:hover >.shop-list-hover{
  height: 208px;
}

.shop-list-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 0;
  flex-direction: column;
  justify-content: space-between;
  background-color: #FFFFFF;
  padding-left: 18px;
  padding-right: 18px;
  overflow: hidden;
  transition: height 0.3s;
}

.slh-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 19px;
}

.slh-title-text{
  margin-left: 22px;
}

.slh-des{
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.slh-button{
  margin-bottom: 19px;
  width: 99px;
  height: 24px;
  background: #DEEFFD;
  border: 1px solid #1890FF;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #1890FF;
  margin-left: auto;
  margin-right: auto;
}

.hot-service{
  display: flex;
  flex-direction: row;
  width: 1200px;
  justify-content: space-between;
  margin-bottom: 58px;
}

.hs-body{
  width: 186px;
  box-shadow: 1px 1px 10px rgba(242, 242, 242, 1);
}

.hs-top{
  height:140px;
  box-shadow: 0 10px 10px 10px rgba(242, 242, 242, 1);

}

.hs-bottom{
  height: 44px;
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  font-weight: 400;
  color: #333333;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(203,225,251,1);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
}
.hs-bottom:hover{
  background-color: rgba(42, 139, 239, 1);
  color: #FFFFFF;
}
.hs-bottom:hover >.hs-bottom-icon{
  color: #FFFFFF;
}
.hs-bottom-icon{
  color: rgba(42, 139, 239, 1);
  font-size: 18px;
}
</style>
